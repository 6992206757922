@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

.custom-zoom {
    transition: transform .2s;
}
.custom-zoom:hover {
    transform: scale(1.02);
}

.custom-card-header3,.custom-card-header4 {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color: #ffffff;
}

.custom-card-footer3,.custom-card-footer4 {
    font-weight: bold;
}

.custom-card-footer3:hover,.custom-card-footer4:hover {
    color: #ffffff;
    font-weight: bold;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.custom-card-footer3:hover,.custom-card-header3 {
    background-color: #3298DC;
}
.custom-card-footer4:hover,.custom-card-header4 {
    background-color: #48C774;
}

a.divLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
    z-index: 10;
    background-color: white;
    opacity: 0;
    filter: alpha(opacity=0);
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}